<template>
  <ui-component-modal
    :modalTitle="
      $t('Components.Reservation.ModalEditReservationDetail.Header_ModalHeader')
    "
    modalSize="medium"
    cssClassModalBody="is-hidden-overflow-x"
    :showModal="showModal"
    :isSaving="isSaving"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || !showEditData"
    :onClickCancel="onClickCancel"
    :onClickSave="saveReservation"
    :disableSaveButton="!mReservation.Name"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <div v-if="mReservation && locationState" class="slider-wrapper">
        <transition-group
          tag="div"
          class="div-slider"
          :name="back ? 'slideback' : 'slide'"
        >
          <!-- Edit reservation -->
          <div v-if="showEditData" :key="1" class="slideContent">
            <table class="table-data table-data table is-fullwidth is-striped">
              <tbody>
                <tr>
                  <td>
                    <span
                      v-text="
                        $t(
                          'Components.Reservation.ModalEditReservationDetail.Label_Name'
                        )
                      "
                    ></span>
                  </td>
                  <td colspan="2">
                    <ui-base-input-field
                      v-model="mReservation.Name"
                      :labelForInput="false"
                      :error="resNameError"
                      :required="true"
                      :showLabel="false"
                      :label="
                        $t(
                          'Components.Reservation.ModalEditReservationDetail.Label_Name'
                        )
                      "
                      @input="$v.mReservation.Name.$touch()"
                      @blur="$v.mReservation.Name.$touch()"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      v-text="
                        $t(
                          'Components.Reservation.ModalEditReservationDetail.Label_Profile'
                        )
                      "
                    ></span>
                  </td>
                  <td v-html="mReservation.ProfileName"></td>
                  <td>
                    <div class="field is-grouped">
                      <p
                        class="control"
                        :class="{
                          'control-noProfile': !mReservation.ProfileName,
                        }"
                      >
                        <a
                          @click="setShowSearchProfile(false)"
                          :class="
                            mReservation.ProfileName
                              ? ``
                              : `button is-small is-info`
                          "
                        >
                          <span v-if="mReservation.ProfileName" class="icon">
                            <font-awesome-icon :icon="['fas', 'edit']" />
                          </span>
                          <span v-else v-text="$t('Form.Control.Add')"></span>
                        </a>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    v-text="
                      $t(
                        'Components.Reservation.ModalEditReservationDetail.Label_Company'
                      )
                    "
                  ></td>
                  <td v-html="mReservation.CompanyName"></td>
                  <td>
                    <div class="field is-grouped">
                      <p
                        class="control"
                        :class="{
                          'control-noCompany': !mReservation.CompanyName,
                        }"
                      >
                        <a
                          @click="setShowSearchCompany(false)"
                          :class="
                            mReservation.CompanyName
                              ? ``
                              : `button is-small is-info`
                          "
                        >
                          <span v-if="mReservation.CompanyName" class="icon">
                            <font-awesome-icon :icon="['fas', 'edit']" />
                          </span>
                          <span v-else v-text="$t('Form.Control.Add')"></span>
                        </a>
                      </p>
                      <p v-if="mReservation.CompanyName" class="control">
                        <a @click="deleteCompany">
                          <span class="icon">
                            <font-awesome-icon
                              :icon="['fas', 'trash-alt']"
                              class="has-text-danger"
                            />
                          </span>
                        </a>
                      </p>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td
                    v-text="
                      $t(
                        'Components.Reservation.ModalEditReservationDetail.Label_Status'
                      )
                    "
                  ></td>
                  <td>
                    <div class="tabs is-toggle is-small">
                      <ul>
                        <li
                          v-for="s in status"
                          :key="s.Id"
                          :class="{
                            'is-active': mReservation.StatusId === s.Id,
                          }"
                        >
                          <a @click="mReservation.StatusId = s.Id">
                            <span>{{ s.Name }}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td
                    v-text="
                      $t(
                        'Components.Reservation.ModalEditReservationDetail.Label_Language'
                      )
                    "
                  ></td>
                  <td>
                    <div class="tabs is-toggle is-small has-text-right">
                      <ul>
                        <li
                          v-for="language in locationState.Languages"
                          :key="language.LanguageId"
                          :class="{
                            'is-active':
                              mReservation.LanguageId === language.LanguageId,
                          }"
                        >
                          <a
                            @click="
                              mReservation.LanguageId = language.LanguageId
                            "
                          >
                            <span>{{
                              $t(
                                'LanguageLabels.Language' + language.LanguageId
                              )
                            }}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr v-if="mReservation.ReservationSetIds.length > 0">
                  <td>
                    {{
                      $t(
                        'Components.Reservation.ModalEditReservationDetail.Label_ChangeAllInSet'
                      )
                    }}
                  </td>
                  <td v-if="reservation.ReservationSetIds.length > 0">
                    <div class="tabs is-toggle is-small">
                      <ul>
                        <li :class="{ 'is-active': changeAllInSet === false }">
                          <a @click="changeAllInSet = false">
                            <span>{{
                              $t(
                                'Components.Reservation.ModalEditReservationDetail.Label_No'
                              )
                            }}</span>
                          </a>
                        </li>
                        <li :class="{ 'is-active': changeAllInSet === true }">
                          <a @click="changeAllInSet = true">
                            <span>{{
                              $t(
                                'Components.Reservation.ModalEditReservationDetail.Label_Yes'
                              )
                            }}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Search company -->
          <div v-if="showSearchCompany" :key="2" class="slideContent">
            <a class="has-icon" @click="setShowEditData">
              <span class="icon is-small">
                <font-awesome-icon :icon="['fas', 'chevron-left']" />
              </span>
              <span v-text="$t('Form.Control.Back')"></span>
            </a>
            <hr />
            <p class="subtitle" v-text="'Search company'"></p>

            <SearchCompany
              @companySelected="setCompany"
              :locationId="reservation.LocationId"
            >
              <template v-slot:top>
                <div class="column-search-company-top column">
                  <div class="is-inline-flex is-align-items-center">
                    <div>
                      - {{ $t('General.Or') }} -
                      <a
                        @click="setShowCreateCompany(false)"
                        v-text="'Create company'"
                      ></a>
                    </div>
                  </div>
                </div>
              </template>
            </SearchCompany>
          </div>

          <!-- Search profile -->
          <div v-if="showSearchProfile" :key="3" class="slideContent">
            <a class="has-icon" @click="setShowEditData">
              <span class="icon is-small">
                <font-awesome-icon :icon="['fas', 'chevron-left']" />
              </span>
              <span v-text="$t('Form.Control.Back')"></span>
            </a>

            <hr />

            <p class="subtitle">Search profile</p>

            <SearchProfile
              @profileSelected="setProfile"
              :locationId="reservation.LocationId"
            >
              <template v-slot:top>
                <div class="column-search-profile-top column">
                  <div class="is-inline-flex is-align-items-center">
                    <div>
                      - {{ $t('General.Or') }} -
                      <a
                        @click="setShowCreateProfile(false)"
                        v-text="
                          $t(
                            'Components.Carts.EditCartData.Button_CreateProfile'
                          )
                        "
                      ></a>
                    </div>
                  </div>
                </div>
              </template>
            </SearchProfile>
          </div>

          <!-- Create company -->
          <div
            key="createCompany"
            v-if="showCreateCompany"
            class="slideContent"
          >
            <a class="has-icon" @click="setShowSearchCompany(true)">
              <span class="icon is-small">
                <font-awesome-icon :icon="['fas', 'chevron-left']" />
              </span>
              <span v-text="$t('Form.Control.Back')"></span>
            </a>
            <hr />

            <p class="subtitle">
              {{ $t('Components.Carts.EditCartData.Subtitle_CreateCompany') }}
            </p>

            <CreateCompany
              :locationId="mReservation.LocationId"
              @nextAction="setCompany"
            />
          </div>

          <!-- Create profile -->
          <div
            key="createProfile"
            v-if="showCreateProfile"
            class="slideContent"
          >
            <a @click="setShowSearchProfile(true)" class="has-icon">
              <span class="icon is-small">
                <font-awesome-icon :icon="['fas', 'chevron-left']" />
              </span>
              <span v-text="$t('Form.Control.Back')"></span>
            </a>
            <hr />

            <p class="subtitle">
              {{ $t('Components.Carts.EditCartData.Subtitle_CreateProfile') }}
            </p>

            <CreateProfile
              :channelId="mReservation.ChannelId"
              :locationId="mReservation.LocationId"
              @nextAction="setProfile"
            />
          </div>
        </transition-group>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import locationProvider from '@/providers/location'
import reservationProvider from '@/providers/reservation'
import { required } from 'vuelidate/lib/validators'

const CreateProfile = () =>
  import(
    /* webpackChunkName: "modal-edit-reservation-detail" */ '@/components/Profiles/CreateProfile'
  )
const SearchCompany = () =>
  import(
    /* webpackChunkName: "modal-edit-reservation-detail" */ '@/components/Companies/SearchCompany'
  )
const SearchProfile = () =>
  import(
    /* webpackChunkName: "modal-edit-reservation-detail" */ '@/components/Profiles/SearchProfile'
  )
const CreateCompany = () =>
  import(
    /* webpackChunkName: "modal-edit-reservation-detail" */ '@/components/Companies/CreateCompany'
  )

export default {
  components: {
    SearchCompany,
    SearchProfile,
    CreateProfile,
    CreateCompany,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
    onClickSave: {
      type: Function,
      required: false,
    },
  },

  data() {
    return {
      back: false,
      changeAllInSet: false,
      languageIds: [],
      mReservation: null,
      selectedCompany: null,
      showEditData: true,
      showCreateCompany: false,
      showCreateProfile: false,
      showSearchCompany: false,
      showSearchProfile: false,
      status: [],
      isSaving: false,
      isSavingError: false,
      channelId: Number(this.$route.params.channelId),
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),
    ...mapState('reservationStore', ['reservation']),

    resNameError() {
      const errors = []
      if (!this.$v.mReservation.Name.$dirty) return errors
      else if (
        !this.$v.mReservation.Name.required ||
        !this.$v.mReservation.Name
      ) {
        errors.push(
          this.$t('Form.InputErrors.RequiredField', {
            fieldLabel: this.$t(
              'Components.Reservation.ModalEditReservationDetail.Label_Name'
            ),
          })
        )
      }
      return errors
    },

    selectedLanguage() {
      let self = this

      if (
        self.locationState &&
        self.locationState.Languages.length > 0 &&
        self.mReservation
      ) {
        let language = self.locationState.Languages.find(
          (l) => l.LanguageId === self.mReservation.LanguageId
        )
        return language
      }
      return null
    },
  },

  /**
   * Form validation rules
   */
  validations: {
    mReservation: {
      Name: {
        required,
      },
    },
  },

  created() {
    this.mReservation = JSON.parse(JSON.stringify(this.reservation))

    this.status.push({ Id: 1, Name: 'Optional' })
    this.status.push({ Id: 2, Name: 'Final' })

    this.getLocationState()
  },

  beforeDestroy() {
    this.selectedCompany = null
  },

  methods: {
    ...mapMutations('locationStore', ['setLocationState']),
    ...mapMutations('reservationStore', ['setReservation']),

    getLocationState() {
      locationProvider.methods
        .getLocationState(this.mReservation.LocationId)
        .then((response) => {
          if (response.status === 200) {
            this.setLocationState(response.data)
          }
        })
    },

    deleteCompany() {
      this.mReservation.CompanyId = 0
      this.mReservation.CompanyName = ''
      this.mReservation.InvoiceAddress.Company = ''
      this.mReservation.InvoiceAddress.Address = ''
      this.mReservation.InvoiceAddress.City = ''
      this.mReservation.InvoiceAddress.PostalCode = ''
      this.mReservation.InvoiceAddress.Country = ''
      this.mReservation.InvoiceAddress.SendTo = ''
      this.mReservation.InvoiceAddress.Email = ''
      this.mReservation.InvoiceAddress.PhoneNumber = ''
      this.mReservation.InvoiceAddress.PoNumberRequired = false
      this.mReservation.InvoiceAddress.PoNumber = ''
    },

    setCompany(company) {
      this.selectedCompany = company
      this.mReservation.CompanyId = company.Id
      this.mReservation.CompanyName = company.Name

      this.mReservation.InvoiceAddress.Address = company.PostAddress
      this.mReservation.InvoiceAddress.City = company.PostCity
      this.mReservation.InvoiceAddress.Postalcode = company.PostPostalCode
      this.mReservation.InvoiceAddress.Country = isNaN(company.PostCountry)
        ? company.PostCountry
        : this.$t(`CountryLabels.Country${company.PostCountry}`)
      this.mReservation.InvoiceAddress.SendTo = company.SendInvoiceTo
      this.mReservation.InvoiceAddress.Email = company.EmailInvoice
      this.mReservation.InvoiceAddress.PhoneNumber = company.Phone
      this.mReservation.InvoiceAddress.PoNumberRequired =
        company.PoNumberRequired
      this.mReservation.InvoiceAddress.PoNumber = ''

      this.setShowEditData()
    },

    setProfile(profile) {
      let self = this

      self.mReservation.ProfileId = profile.Id
      self.mReservation.ProfileName = profile.FullName

      self.setShowEditData()
    },

    saveReservation() {
      let self = this

      self.isSaving = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        // 'ERROR'
      } else {
        reservationProvider.methods
          .saveReservationBasic(
            self.mReservation.Id,
            self.mReservation.Name,
            self.mReservation.ProfileId,
            self.mReservation.CompanyId,
            self.mReservation.StatusId,
            self.selectedLanguage.Language,
            self.changeAllInSet
          )
          .then((response) => {
            if (response.status === 200) {
              // Update reservation store
              self.setReservation(response.data)

              EventBus.$emit('showToast', {
                type: 'info',
                message: 'Reservation data updated!',
              })

              self.onClickCancel()
            }
          })
          .catch((error) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },

    setShowEditData() {
      this.setVisibleItem('edit', true)
    },

    setShowSearchCompany(slideDirection = false) {
      this.back = false
      this.setVisibleItem('searchCompany', slideDirection)
    },

    setShowSearchProfile(slideDirection = false) {
      this.back = false
      this.setVisibleItem('searchProfile', slideDirection)
    },

    setShowCreateCompany(slideDirection = false) {
      this.setVisibleItem('createCompany', slideDirection)
    },

    setShowCreateProfile(slideDirection = false) {
      this.setVisibleItem('createProfile', slideDirection)
    },

    setVisibleItem(item, slideDirection) {
      this.back = slideDirection
      this.showEditData = item === 'edit'
      this.showCreateCompany = item === 'createCompany'
      this.showCreateProfile = item === 'createProfile'
      this.showSearchCompany = item === 'searchCompany'
      this.showSearchProfile = item === 'searchProfile'
    },
  },
}
</script>

<style lang="scss" scoped>
.slider-wrapper {
  .table-data {
    td {
      vertical-align: middle;
      &:first-child {
        white-space: nowrap;
      }
      &:nth-child(2) {
        width: 100%;
      }

      .mCart.ProfileName,
      .control-noCompany {
        width: 100%;
        .button {
          width: 100%;
        }
      }
    }
  }
  .column-search-profile-top,
  .column-search-company-top {
    > div {
      height: 100%;
    }
  }
}
</style>
